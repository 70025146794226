/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Adobe Fonts */
@import url("https://use.typekit.net/ind5iby.css");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

/* Body Reset & Font */
body {
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  scroll-behavior: smooth;
}

/* Extras */
.bg-gradient {
  background: linear-gradient(
    137.22deg,
    rgba(123, 31, 162, 0.75) -10.67%,
    rgba(98, 54, 255, 0.75) 103.62%
  );
}

p {
  color: #201e21;
}

@media screen and (max-width: 520px) {
  p {
    font-size: 14px !important;
  }
}

input {
  color: #201e21;
}

.overlay {
  background: rgba(0, 0, 0, 0.23);
  background-blend-mode: multiply;
}

.overlay-section3 {
  background: linear-gradient(
    270.18deg,
    #ffffff 21.88%,
    rgba(227, 227, 227, 0.528166) 73.01%,
    rgba(196, 196, 196, 0) 99.87%
  );
}

.img-gradient {
  background: linear-gradient(133.48deg, #7b1fa2 10.34%, #6236ff 88.73%);
}

.sidebar-shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

.image-section3 {
  background-image: url("./images/Background.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
}
